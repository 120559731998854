import React from "react";

import { MDBDataTableV5 } from "mdbreact";

export default function Basic(props) {
  let userAttributes;
  let i = 0;

  let datatable;
  [datatable] = React.useState("");

  if (props.subscriberKeys !== undefined) {

    userAttributes = [];
    for (const key of Object.keys(props.subscriberKeys)) {
      for (const keynetwork of Object.keys(props.subscriberKeys[key])) {
        if (props.subscriberKeys[key][keynetwork]["KeyTag"] !== undefined) {
          userAttributes.push({
            ssid: props.subscriberKeys[key]["ssid"],
            keytag: props.subscriberKeys[key][keynetwork]["KeyTag"],

            dateStart: Intl.DateTimeFormat("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "numeric",
            }).format(new Date(props.subscriberKeys[key][keynetwork]["dateAddedUTC.ISO8601"])),
            
            action: (
              <>
                <div className="showPasswordButton">
                  <a href="#link" onClick={() => props.toggleShowWifiPassword(key, keynetwork)} className="expirationAction">
                    Show Wi-Fi Password
                  </a>
                </div>
              </>
            ),
          });
          i = i + 1;
        }
      }
    }
  }
  datatable = ({
    columns: [
      {
        label: "Network Name",
        field: "ssid",
      },
      {
        label: "Keytag",
        field: "keytag",
      },
      {
        field: "action"
      }
    ],
    rows: userAttributes,
  });
  return (
    <MDBDataTableV5
      sortable={true}
      responsive
      hover
      data={datatable}
      searchTop={true}
      searchBottom={false}
      searchLabel="Filter"
      paging={false}
      className="servicePlans"
      info={false}
      noRecordsFoundLabel="No Keys Found"
      sortRows={["activated"]}
    />
  );
}