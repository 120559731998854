import React, { Component } from "react";

import { MDBModal, MDBModalHeader, MDBModalBody } from "mdbreact";

import TandC from "../../components/CreateAccount/TandC";

import brandsData from "../../brands/common/brands.json";

let BrandName;

let PhoneNumber;

let PrivacyPolicy;

export const SUB_PAGES = brandsData.forEach(
	({ name, url, phone_number, privacy_policy }) => {
		if (url.includes(window.location.hostname)) {
			PrivacyPolicy = privacy_policy;
			BrandName = name;
			PhoneNumber = phone_number;
		}
	}
);

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tandc: false,
			modalTandC: false,
			loadingTandC: false,
		};
	}

	toggleTandC = () => {
		this.setState({
			modalTandC: !this.state.modalTandC,
		});
	};

	render() {
		return (
			<>
				<MDBModal
					isOpen={this.state.modalTandC}
					size="lg"
					className="form"
					centered
					overflowScroll
				>
					<MDBModalHeader toggle={this.toggleTandC}>
						{BrandName} Terms and Conditions
					</MDBModalHeader>
					<MDBModalBody className="faqModal" id="faq">
						<TandC />
						<button
							className="btn Ripple-parent btn-default nextButton"
							onClick={this.toggleTandC}
						>
							Close
						</button>
					</MDBModalBody>
				</MDBModal>
				<div className="footerCreateAccount">
					<p>
						{BrandName} Support {PhoneNumber}
					</p>
					<p className="text-piped">
						<span className="piped">
							&copy; Copyright 2012 - {new Date().getFullYear()}{" "}
							{BrandName}
						</span>
						<span className="piped">All Rights Reserved</span>
						<span className="piped">
							<button onClick={this.toggleTandC}>Terms of Service </button>
						</span>
						{PrivacyPolicy!=="#" ? 
						<span className="piped">
							<a href={PrivacyPolicy} target="_blank" rel="noreferrer">
								Privacy Policy
							</a>
						</span>
						: "" }
					</p>
				</div>
			</>
		);
	}
}

export default Footer;