import React, { Component } from "react";

import { MDBModalBody, MDBModalHeader, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption, MDBRow, MDBCol, MDBIcon, MDBModal, toast } from "mdbreact";

import { withTranslation } from "react-i18next";

import AddNewVenue from "../../../../components/NetworkMgmt/Forms/AddVenue/AddVenueWizard";

import ModifyVenue from "../../../../components/NetworkMgmt/Forms/ModifyVenue/ModifyVenue";

import ViewModifyVenues from "../../../../components/NetworkMgmt/Venues/ViewVenues";

import AddUnit from "../../../../components/NetworkMgmt/Forms/AddUnit/AddUnit";

import ServicesEndingSoonGuests from "../../../../components/VenueMgmt/UpcomingExpirations/ServicesEndingSoonGuests";

import DownloadUnits from "../../../../components/NetworkMgmt/Forms/BulkDownloadUnits/BulkDownloadUnits";

import UploadUnits from "../../../../components/NetworkMgmt/Forms/BulkUploadUnits/BulkUploadUnits";

import AddDomainAdministrator from "../../../../components/NetworkMgmt/Forms/AddDomainAdministrator/AddDomainAdministrator";

import axios from "../../../../axios";

import _ from "lodash";


let getResults;

let source;

let source1 = [];

class VenueManagementNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalViewModifyVenues: false,
      modalModifyVenue: false,
      modalResendActivation: false,
      modalServicesEndingSoon: false,
      loading: false,
      loadingUnit: false,
      venueInfo: undefined,
      optionTemplateVenues: this.props.optionTemplateVenues
    };
  }

  componentDidMount() {
    this.getVenues();
  }

  toggleAddNewVenue = () => {
    this.setState({
      modalAddNewVenue: !this.state.modalAddNewVenue,
      tabNumber: 1,
      tabChanged: true,
      venueID: undefined,
      toggleConfirmContinueOpen: false,
      modal: false
    });
  };

  toggleModifyVenue = () => {
    this.setState({
      modalModifyVenue: !this.state.modalModifyVenue,
    });
  };
  toggleViewModifyVenues = () => {
    this.setState({
      modalViewModifyVenues: !this.state.modalViewModifyVenues,
    });
  };

  toggleAddUnit = () => {
    this.setState({
      modalAddUnit: !this.state.modalAddUnit,
    });
  };

  toggleServicesEndingSoon = () => {
    this.setState({
      modalServicesEndingSoon: !this.state.modalServicesEndingSoon,
    });
  };

  toggleDownloadUnits = () => {
    this.setState({
      modalDownloadUnits: !this.state.modalDownloadUnits,
    });
  };

  toggleUploadUnits = () => {
    this.setState({
      modalUploadUnits: !this.state.modalUploadUnits,
      selectedID: undefined
    });
  };

  toggleConfirmContinue = () => {
    this.setState({
      toggleConfirmContinueOpen: true,
      modal: !this.state.modal,
      // modalAddNewVenue: !this.state.modalAddNewVenue
    });
  };

  getVenueDetails = (index) => {
    this.setState({ loadingVenueDetails: true });
    let cookieVenueName = sessionStorage.getItem("venueID");
    cookieVenueName === null
      ? (cookieVenueName = "")
      : (index = cookieVenueName);
    this.setState({
      unitData: undefined,
      expiringData: undefined,
      searchData: undefined,
      value: "",
      servicePlans: undefined,
      keyPoolList: undefined,
      venueAdminList: {},
      venueEquipList: undefined,
      venueLeasingAgentList: {},
      staffArrayAll: undefined,
      venueInfo: undefined,
      hasUpdated: false,
      loading: true,
    });
    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      VenueID: index,
      UserTimeZone: new Date().getTimezoneOffset(),
    };
    let key = [];
    let iteration = 0;
    let lastName = [];
    let firstName = [];
    let unitID = [];
    let unitID_internal = [];
    let keyID = [];
    let email = [];
    let startDate = [];
    let contactpref_email = [];
    let contactpref_sms = [];
    let phoneNumber = [];
    let locale = [];
    let endDate = [];
    let ssid = [];
    let servicePlanID = [];
    let servicePlan = [];
    let vlan = [];
    let residentStatus = [];
    source1 = [];
    this.setState({
      activeData: undefined,
      activeData1Day: undefined,
      activeData7Day: undefined,
      expiringData: undefined,
      expiringData14Days: undefined,
      searchData: undefined,
      guestDataExpiring: undefined,
      value: "",
    });
    if (index !== undefined) {
      axios
        .post("/networkoperatorconsole/venues/listVenueDetails", body, {
          headers: {},
        })
        .then((response) => {
          var sortedKeys = _(response.data[index]["Keypools"])
            .map(function (v, k) {
              // insert the key into the object
              return _.merge({}, v, { key: k });
            })
            .sortBy("DisplayOrder") // sort by name
            .value();
          this.setState({
            leasingAgents: [],
            venueAdmins: [],
          });
          this.setState({
            address1: response.data[index]["AddressLine1"],
            address2: response.data[index]["AddressLine2"],
            city: response.data[index]["City"],
            region: response.data[index]["State"],
            country: response.data[index]["Country.ISO3166A3"],
            postalcode: response.data[index]["PostalCode"],
            unitData:
              response.data[index]["Units-Building-Floor-List-Subscribers"],
            defaultSubscriptionDurationRes:
              response.data[index][
              "DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess"
              ],
            selectDefaultServicePlanResident: response.data[index][
              "DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess"
            ],
            selectDefaultServicePlanWireless: response.data[index][
              "DefaultServicePlanID_LocalWirelessAccess"
            ],
            venueServicePlans: response.data[index]["AvailableServicePlans"],
            hasUpdated: true,
            activeData:
              response.data[index][
              "RecentlyStartedSubscriptionsList_7day"
              ],
            activeData1Day:
              response.data[index][
              "UpcomingSubscriptionsList_1day"
              ],
            activeData7Day:
              response.data[index][
              "UpcomingSubscriptionsList_7day"
              ],
            allActiveData: response.data[index]["Subscriptions"],
            expiringData:
              response.data[index][
              "UpcomingSubscriptionEndList_7day_unconfirmed"
              ],
            expiringData14Days:
              response.data[index][
              "UpcomingSubscriptionEndList_14day"
              ],
            searchData:
              response.data[index]["UnitsAndSubscriptions"],
            UnitsBuildingFloorListSusbcribers:
              response.data[index][
              "Units-Building-Floor-List-Subscribers"
              ],
            venueType: response.data[index]["VenueType"],
            venueInfo: response.data[index],
            keypoolID: Object.keys(
              response.data[index]["Keypools"]
            )[0],
            servicePlans:
              response.data[index]["AvailableServicePlans"],
            propMgmtID:
              response.data[index]["PropertyManagementGroup"][
              "PropertyManagementGroupID"
              ],
            sharedSecret:
              response.data[index]["PropertyManagementGroup"][
              "SharedSecret"
              ],
            keyPoolList: sortedKeys,
            venueAdminList:
              response.data[index]["VenueAdministrators"],
            venueLeasingAgentList:
              response.data[index]["LeasingAgents"],
            venueEquipList:
              response.data[index]["CoreEquipmentStatus"],
          });
          this.setState({
            address1: response.data[index]["AddressLine1"],
            address2: response.data[index]["AddressLine2"],
            city: response.data[index]["City"],
            region: response.data[index]["State"],
            country: response.data[index]["Country.ISO3166A3"],
            postalcode: response.data[index]["PostalCode"],
            unitData:
              response.data[index]["Units-Building-Floor-List-Subscribers"],
            defaultSubscriptionDurationRes:
              response.data[index][
              "DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess"
              ],
            venueServicePlans: response.data[index]["AvailableServicePlans"],
            hasUpdated: true,
            currencyName: response.data[index]["ShoppingCart"]["Currencies"][0]
          });
          let optionTemplateServicePlans = [];
          Object.keys(
            response.data[index]["AvailableServicePlans"]
          ).map((value, i) => {
            optionTemplateServicePlans.push({ value: response.data[index]["AvailableServicePlans"][value]['ServicePlanID'], name: response.data[index]["AvailableServicePlans"][value]['ServicePlanName'] });
            return true;
          });
          this.setState({
            optionTemplateServicePlans: optionTemplateServicePlans,
            loading: false
          });
          this.getAdmins();
          this.setState({ loadingVenueDetails: false });
          this.setState({ unitsArrayAll: undefined });
          let unitsArrayAll = [];
          let unitsArraySub = [];
          let unitsArrayGuest = [];
          let unitsArrayRoaming = [];
          let building;
          let floor;
          var obj = {};
          for (
            let i = 0;
            i <
            Object.keys(
              response.data[index][
              "Units-Building-Floor-List-Subscribers"
              ]
            ).length;
            i++
          ) {
            obj[i] = {};
            let unitID = Object.keys(
              response.data[index][
              "Units-Building-Floor-List-Subscribers"
              ]
            )[i];
            for (
              let j = 0;
              j <
              Object.keys(
                response.data[index][
                "Units-Building-Floor-List-Subscribers"
                ][unitID]
              ).length;
              j++
            ) {

              obj[i][j] = {};
              building = Object.keys(
                response.data[index][
                "Units-Building-Floor-List-Subscribers"
                ][unitID]
              )[j];
              for (
                var k = 0;
                k <
                Object.keys(
                  response.data[index][
                  "Units-Building-Floor-List-Subscribers"
                  ][unitID][building]
                ).length;
                k++
              ) {
                floor = Object.keys(
                  response.data[index][
                  "Units-Building-Floor-List-Subscribers"
                  ][unitID][building]
                )[k];
                obj[i][j][k] = {
                  UnitID: unitID,
                  Building: building,
                  Floor: floor,
                  Purpose:
                    response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["purpose"],
                  vlan: response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                  ][unitID][building][floor]["vlan"],
                  address1:
                    response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["AddressLine1"],
                  address2:
                    response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["AddressLine2"],
                  city: response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                  ][unitID][building][floor]["City"],
                  state: response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                  ][unitID][building][floor]["State"],
                  country:
                    response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor][
                    "Country.ISO3166A3"
                    ],
                  postalcode:
                    response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["PostalCode"],
                  hasActiveSubscription:
                    response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor][
                    "hasActiveOrFutureSubscription"
                    ],
                  subscriptions:
                    response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["Subscriptions"],
                  unitID_internal:
                    response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                    ][unitID][building][floor]["UnitID_internal"],

                  realpageSyncDate: response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                  ][unitID][building][floor]["RealPageSyncDateTimeUTC.ISO8601"],
                  smsCode: response.data[index][
                    "Units-Building-Floor-List-Subscribers"
                  ][unitID][building][floor]["SMSCode"],
                  shoppingCartPriceAdjustment: response.data[index]["Units-Building-Floor-List-Subscribers"][unitID][building][floor]["ShoppingCartPriceAdjustment"],
                  externalVenueUnitID: response.data[index]["Units-Building-Floor-List-Subscribers"][unitID][building][floor]["externalVenueUnitID"],
                  allowOverlappingSubscriptionWiredUnit: response.data[index]["Units-Building-Floor-List-Subscribers"][unitID][building][floor]["allowOverlappingSubscriptionWiredUnit"],
                  primaryUnitID_Internal: response.data[index]["Units-Building-Floor-List-Subscribers"][unitID][building][floor]["PrimaryUnitID_internal"],
                  selectedServicePlanPrice: response.data[index]["Units-Building-Floor-List-Subscribers"][unitID][building][floor]["ListBillingCyclePrice"],
                  enableShoppingCart: response.data[index]["Units-Building-Floor-List-Subscribers"][unitID][building][floor]["enableShoppingCart"]
                };
                unitsArraySub.push(obj[i][j][k]);
              }
            }
          }
          for (
            let i = 0;
            i <
            Object.keys(
              response.data[index][
              "Units-Building-Floor-List-GuestNetworks"
              ]
            ).length;
            i++
          ) {
            obj[i] = {};
            let unitID = Object.keys(
              response.data[index][
              "Units-Building-Floor-List-GuestNetworks"
              ]
            )[i];
            for (
              let j = 0;
              j <
              Object.keys(
                response.data[index][
                "Units-Building-Floor-List-GuestNetworks"
                ][unitID]
              ).length;
              j++
            ) {
              let building = Object.keys(
                response.data[index][
                "Units-Building-Floor-List-GuestNetworks"
                ][unitID]
              )[j];
              let floor = Object.keys(
                response.data[index][
                "Units-Building-Floor-List-GuestNetworks"
                ][unitID][building]
              )[j];
              obj[i][j] = {
                UnitID: unitID,
                Building: building,
                Floor: floor,
                Purpose:
                  response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["purpose"],
                vlan: response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                ][unitID][building][floor]["vlan"],
                address1:
                  response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["AddressLine1"],
                address2:
                  response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["AddressLine2"],
                city: response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                ][unitID][building][floor]["City"],
                state: response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                ][unitID][building][floor]["State"],
                country:
                  response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor][
                  "Country.ISO3166A3"
                  ],
                postalcode:
                  response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["PostalCode"],
                hasActiveSubscription:
                  response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor][
                  "hasActiveOrFutureSubscription"
                  ],
                subscriptions:
                  response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["Subscriptions"],
                unitID_internal:
                  response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                  ][unitID][building][floor]["UnitID_internal"],

                realpageSyncDate: response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                ][unitID][building][floor]["RealPageSyncDateTimeUTC.ISO8601"],
                smsCode: response.data[index][
                  "Units-Building-Floor-List-GuestNetworks"
                ][unitID][building][floor]["SMSCode"],
                shoppingCartPriceAdjustment: response.data[index]["Units-Building-Floor-List-GuestNetworks"][unitID][building][floor]["ShoppingCartPriceAdjustment"],
                externalVenueUnitID: response.data[index]["Units-Building-Floor-List-GuestNetworks"][unitID][building][floor]["externalVenueUnitID"],
                allowOverlappingSubscriptionWiredUnit: response.data[index]["Units-Building-Floor-List-GuestNetworks"][unitID][building][floor]["allowOverlappingSubscriptionWiredUnit"],
                primaryUnitID_Internal: response.data[index]["Units-Building-Floor-List-GuestNetworks"][unitID][building][floor]["PrimaryUnitID_internal"],
                selectedServicePlanPrice: response.data[index]["Units-Building-Floor-List-GuestNetworks"][unitID][building][floor]["ListBillingCyclePrice"],
                enableShoppingCart: response.data[index]["Units-Building-Floor-List-GuestNetworks"][unitID][building][floor]["enableShoppingCart"]
              };
              unitsArrayGuest.push(obj[i][j]);
            }
          }
          for (
            var i = 0;
            i <
            Object.keys(
              response.data[index][
              "Units-Building-Floor-List-RoamingNetworks"
              ]
            ).length;
            i++
          ) {
            obj[i] = {};
            let unitID = Object.keys(
              response.data[index][
              "Units-Building-Floor-List-RoamingNetworks"
              ]
            )[i];
            for (
              var j = 0;
              j <
              Object.keys(
                response.data[index][
                "Units-Building-Floor-List-RoamingNetworks"
                ][unitID]
              ).length;
              j++
            ) {
              let building = Object.keys(
                response.data[index][
                "Units-Building-Floor-List-RoamingNetworks"
                ][unitID]
              )[j];
              let floor = Object.keys(
                response.data[index][
                "Units-Building-Floor-List-RoamingNetworks"
                ][unitID][building]
              );
              obj[i][j] = {
                UnitID: unitID,
                Building: building,
                Floor: floor,
                Purpose:
                  response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["purpose"],
                vlan: response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                ][unitID][building][floor]["vlan"],
                address1:
                  response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["AddressLine1"],
                address2:
                  response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["AddressLine2"],
                city: response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                ][unitID][building][floor]["City"],
                state: response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                ][unitID][building][floor]["State"],
                country:
                  response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor][
                  "Country.ISO3166A3"
                  ],
                postalcode:
                  response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["PostalCode"],
                hasActiveSubscription:
                  response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor][
                  "hasActiveOrFutureSubscription"
                  ],
                subscriptions:
                  response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["Subscriptions"],
                unitID_internal:
                  response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                  ][unitID][building][floor]["UnitID_internal"],
                realpageSyncDate: response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                ][unitID][building][floor]["RealPageSyncDateTimeUTC.ISO8601"],
                smsCode: response.data[index][
                  "Units-Building-Floor-List-RoamingNetworks"
                ][unitID][building][floor]["SMSCode"],
                shoppingCartPriceAdjustment: response.data[index]["Units-Building-Floor-List-RoamingNetworks"][unitID][building][floor]["ShoppingCartPriceAdjustment"],
                externalVenueUnitID: response.data[index]["Units-Building-Floor-List-RoamingNetworks"][unitID][building][floor]["externalVenueUnitID"],
                allowOverlappingSubscriptionWiredUnit: response.data[index]["Units-Building-Floor-List-RoamingNetworks"][unitID][building][floor]["allowOverlappingSubscriptionWiredUnit"],
                primaryUnitID_Internal: response.data[index]["Units-Building-Floor-List-RoamingNetworks"][unitID][building][floor]["PrimaryUnitID_internal"],
                selectedServicePlanPrice: response.data[index]["Units-Building-Floor-List-RoamingNetworks"][unitID][building][floor]["ListBillingCyclePrice"],
                enableShoppingCart: response.data[index]["Units-Building-Floor-List-RoamingNetworks"][unitID][building][floor]["enableShoppingCart"]
              };
              unitsArrayRoaming.push(obj[i][j]);
            }
          }

          unitsArrayAll = unitsArraySub.concat(
            unitsArrayGuest,
            unitsArrayRoaming
          );
          this.setState({ unitsArrayAll: [...unitsArrayAll] });
          key = Object.keys(response.data[index]["Subscriptions"]);
          key.forEach((val) => {
            if (
              response.data[index]["Subscriptions"][
              key[iteration]
              ]["StartDateTimeUTC.ISO8601"] !== ""
            ) {
              lastName =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["SubscriberFamilyName"];
              firstName =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["SubscriberGivenName"];
              unitID =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["UnitID"];
              unitID_internal =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["UnitID_internal"];
              startDate =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["StartDateTimeUTC.ISO8601"];
              contactpref_email =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["contactpref_email"];
              contactpref_sms =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["contactpref_sms"];
              phoneNumber =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["SubscriberPhoneNumber.E164"];
              locale =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["SubscriberLocale"];
              endDate =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["EndDateTimeUTC.ISO8601"];
              floor =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["UnitFloor"];
              building =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["UnitBuilding"];
              email =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["SubscriberEmail"];
              ssid =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["ssid"];
              keyID = key[iteration];
              servicePlanID =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["ServicePlan"]["ServicePlanID"];
              servicePlan =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["ServicePlan"]["ServicePlanName"];
              vlan =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["vlan"];
              residentStatus =
                response.data[index]["Subscriptions"][
                key[iteration]
                ]["Status"];
              getResults = () =>
                _.times(1, () => ({
                  firstName: firstName,
                  lastName: lastName,
                  unitID: unitID,
                  unitID_internal: unitID_internal,
                  startDate: startDate,
                  contactpref_email: contactpref_email,
                  contactpref_sms: contactpref_sms,
                  phoneNumber: phoneNumber,
                  locale: locale,
                  endDate: endDate,
                  floor: floor,
                  building: building,
                  title: firstName + " " + lastName,
                  description:
                    "Unit: " +
                    unitID +
                    "\r\n Building: " +
                    building +
                    ", Floor: " +
                    floor +
                    "\r\n" +
                    email,
                  keyid: keyID,
                  email: email,
                  ssid: ssid,
                  servicePlanID: servicePlanID,
                  servicePlan: servicePlan,
                  vlan: vlan,
                  status: residentStatus,
                }));
              source = _.range(0, 3).reduce((memo1) => {
                const name = val;

                // eslint-disable-next-line no-param-reassign
                memo1 = {
                  name,
                  results: getResults(),
                };
                return memo1;
              }, {});
              source1.push(source);

              iteration = iteration + 1;
            }
          });
          this.setState({
            hasUpdated: true
          });
          this.setState({
            loading: false
          });
        })
        .catch((error) => {
          console.log("🚀 ~ file: VenueManagementNavigation.js:692 ~ VenueManagementNavigation ~ error", error)
          this.setState({ showError: true });
          // this.setState({ errorMessage: "The listVenueDetails API timed out"})
          this.setState({ error: true });
          this.setState({
            hasUpdated: true,
            loading: false
          })
        });
    }
  };

  getAdmins = () => {
    this.setState({ staffArrayAll: undefined });
    let staffArrayAll = [];
    let staffArrayVA = [];
    let staffArrayLeasing = [];
    for (var i = 0; i < this.state.venueAdminList.length; i++) {
      staffArrayVA[i] = {
        [this.state.venueAdminList[i]]: "Venue Administrator",
      };
    }
    for (var j = 0; j < this.state.venueLeasingAgentList.length; j++) {
      staffArrayLeasing[j] = {
        [this.state.venueLeasingAgentList[j]]: "Leasing Agent",
      };
    }
    staffArrayAll = staffArrayVA.concat(staffArrayLeasing);
    this.setState({ staffArrayAll: [...staffArrayAll] });
  };

  addVenue = (
    venueName,
    email,
    address1,
    address2,
    phoneNumber,
    country,
    city,
    region,
    zipcode,
    group,
    countdown,
    durationResident,
    durationGuest,
    firstName,
    lastName,
    minVlanRes,
    maxVlanRes,
    minVlanGuest,
    maxVlanGuest,
    minVlanRoaming,
    maxVlanRoaming,
    venueType,
    enableShoppingCart,
    sendSubscriptionAddedEmail,
    sendSubscriptionEndEmail,
    allowOverlapping,
    displayFCCLabel,
    typicalLatency) => {
    if (address2 === undefined) {
      address2 = "";
    }
    this.setState({ loading: true });
    this.setState({ reloadVenueList: true });
    const data = {
      VenueName: venueName,
      AddressLine1: address1,
      AddressLine2: address2,
      City: city,
      State: region,
      PostalCode: zipcode,
      "Country.ISO3166A3": country,
      PhoneNumber: phoneNumber,
      EmailAddress: email,
      PropertyManagementGroupID: group,
      AccessToken: sessionStorage.getItem("AccessToken"),
      DefaultVLANForRoamingSubscribers: "2000",
      WelcomeEmail_countdown_hours: countdown,
      "DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess": durationResident,
      "DefaultSubscriptionDurationHours_LocalWirelessAccess": durationGuest,
      PrimaryContactGivenName: firstName,
      PrimaryContactFamilyName: lastName,
      "MinimumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess": minVlanRes,
      "MaximumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess": maxVlanRes,
      "MinimumAutoGeneratedVLAN_LocalWirelessAccess": minVlanGuest,
      "MaximumAutoGeneratedVLAN_LocalWirelessAccess": maxVlanGuest,
      "MinimumAutoGeneratedVLAN_GlobalWirelessRoaming": minVlanRoaming,
      "MaximumAutoGeneratedVLAN_GlobalWirelessRoaming": maxVlanRoaming,
      VenueType: venueType,
      enableShoppingCart: enableShoppingCart,
      sendSubscriptionAddedEmail: sendSubscriptionAddedEmail,
      sendSubscriptionEndEmail: sendSubscriptionEndEmail,
      allowOverlappingSubscriptionWiredUnit: allowOverlapping,
      FCCLabel: {
       displayLabel: displayFCCLabel,
       TypicalLatency: typicalLatency
      }
    };
    axios
      .post("/networkoperatorconsole/venues/addVenue", data, {
        headers: {},
      })

      .then(
        (response) => {
          this.getVenues();
          this.getVenueDetails(response.data.VenueID)
          this.props.updateName(venueName, response.data.VenueID);
          this.setState({ loading: false });
          this.toggleConfirmContinue();
          /* If Venue Wizard is enabled, comment this line out (toast.success)*/
          // toast.success("Added Venue!", {
          //   closeButton: false,
          // });
          this.setState({
            reloadVenueList: false,
            venueID: response.data.VenueID,
            tabNumber: 2,
            tabChanged: true
          });
          sessionStorage.setItem("venueName", venueName)
        },
        (error) => {
          toast.error(error.response.data.message, {
            closeButton: false,
          });
          this.setState({
            loading: false,
            reloadVenueList: false,
            tabNumber: 1,
            tabChanged: false
          });
        }
      );
  };

  modifyVenueInfo = (
    address1,
    address2,
    phoneNumber,
    country,
    city,
    region,
    zipcode,
    email,
    countdown,
    durationResident,
    durationGuest,
    contactFirstName,
    contactLastName,
    minVlanRes,
    maxVlanRes,
    minVlanGuest,
    maxVlanGuest,
    minVlanRoaming,
    maxVlanRoaming,
    realpageSiteID,
    realpagePMCID,
    realpageSyncDate,
    venueType,
    shoppingCartPriceAdjustment,
    enableRealPageSync,
    enableShoppingCart,
    sendSubscriptionAddedEmail,
    sendSubscriptionEndEmail,
    allowOverlapping,
    trialCycleAdjustment,
    stripePublishableKey,
    stripeSecretKey,
    stripeWebhookSigningSecret,
    venueName,
    displayFCCLabel,
    typicalLatency
  ) => {
    if (address2 === undefined) {
      address2 = "";
    }
    this.setState({ loading: true });
    this.setState({ reloadVenueList: true });
    const updateVenueAddress = {
      VenueID: sessionStorage.getItem("venueID"),
      AddressLine1: address1,
      AddressLine2: address2,
      City: city,
      State: region,
      PostalCode: zipcode,
      "Country.ISO3166A3": country,
      AccessToken: sessionStorage.getItem("AccessToken"),
    };
    let updateVenue;
    typicalLatency !== "" ? 
     updateVenue = {
      VenueID: sessionStorage.getItem("venueID"),
      VenueName: venueName,
      AccessToken: sessionStorage.getItem("AccessToken"),
      WelcomeEmail_countdown_hours: countdown,
      "DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess": durationResident,
      "DefaultSubscriptionDurationHours_LocalWirelessAccess": durationGuest,
      PrimaryContactGivenName: contactFirstName,
      PrimaryContactFamilyName: contactLastName,
      PrimaryContactEmailAddress: email,
      "PrimaryContactPhoneNumber.E164": phoneNumber,
      "MinimumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess": minVlanRes,
      "MaximumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess": maxVlanRes,
      "MinimumAutoGeneratedVLAN_LocalWirelessAccess": minVlanGuest,
      "MaximumAutoGeneratedVLAN_LocalWirelessAccess": maxVlanGuest,
      "MinimumAutoGeneratedVLAN_GlobalWirelessRoaming": minVlanRoaming,
      "MaximumAutoGeneratedVLAN_GlobalWirelessRoaming": maxVlanRoaming,
      "RealPageSiteId": realpageSiteID,
      "RealPagePMCId": realpagePMCID,
      "RealPageSyncDateTimeUTC.ISO8601": realpageSyncDate,
      VenueType: venueType,
      "ShoppingCartPriceAdjustment": -Math.abs(shoppingCartPriceAdjustment) * 100,
      enableRealPageSync: enableRealPageSync,
      enableShoppingCart: enableShoppingCart,
      sendSubscriptionAddedEmail: sendSubscriptionAddedEmail,
      sendSubscriptionEndEmail: sendSubscriptionEndEmail,
      allowOverlappingSubscriptionWiredUnit: allowOverlapping,
      TrialCycleAdjustment: trialCycleAdjustment,
      StripePublishableKey: stripePublishableKey,
      Stripe_Secret_key: stripeSecretKey,
      Stripe_Webhook_signingsecret: stripeWebhookSigningSecret,
      FCCLabel: {
       displayLabel: displayFCCLabel,
       TypicalLatency: typicalLatency
      }
    } : 
    updateVenue = {
     VenueID: sessionStorage.getItem("venueID"),
     VenueName: venueName,
     AccessToken: sessionStorage.getItem("AccessToken"),
     WelcomeEmail_countdown_hours: countdown,
     "DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess": durationResident,
     "DefaultSubscriptionDurationHours_LocalWirelessAccess": durationGuest,
     PrimaryContactGivenName: contactFirstName,
     PrimaryContactFamilyName: contactLastName,
     PrimaryContactEmailAddress: email,
     "PrimaryContactPhoneNumber.E164": phoneNumber,
     "MinimumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess": minVlanRes,
     "MaximumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess": maxVlanRes,
     "MinimumAutoGeneratedVLAN_LocalWirelessAccess": minVlanGuest,
     "MaximumAutoGeneratedVLAN_LocalWirelessAccess": maxVlanGuest,
     "MinimumAutoGeneratedVLAN_GlobalWirelessRoaming": minVlanRoaming,
     "MaximumAutoGeneratedVLAN_GlobalWirelessRoaming": maxVlanRoaming,
     "RealPageSiteId": realpageSiteID,
     "RealPagePMCId": realpagePMCID,
     "RealPageSyncDateTimeUTC.ISO8601": realpageSyncDate,
     VenueType: venueType,
     "ShoppingCartPriceAdjustment": -Math.abs(shoppingCartPriceAdjustment) * 100,
     enableRealPageSync: enableRealPageSync,
     enableShoppingCart: enableShoppingCart,
     sendSubscriptionAddedEmail: sendSubscriptionAddedEmail,
     sendSubscriptionEndEmail: sendSubscriptionEndEmail,
     allowOverlappingSubscriptionWiredUnit: allowOverlapping,
     TrialCycleAdjustment: trialCycleAdjustment,
     StripePublishableKey: stripePublishableKey,
     Stripe_Secret_key: stripeSecretKey,
     Stripe_Webhook_signingsecret: stripeWebhookSigningSecret,
     FCCLabel: {
      displayLabel: displayFCCLabel
     }
   };
    Promise.all([
      axios.post(
        "/networkoperatorconsole/venues/updateVenueAddress",
        updateVenueAddress,
        { headers: {} }
      ),
      axios.post("/networkoperatorconsole/venues/configureVenue", updateVenue, {
        headers: {},
      }),
    ])
      .then(async ([res1, res2]) => {
        toast.success("Updated Venue!", {
          closeButton: false,
        });
        this.setState({ loading: false });
        this.toggleModifyVenue();
        this.setState({ reloadVenueList: false });
        this.props.updateName(sessionStorage.getItem("venueName"), sessionStorage.getItem("venueID"));
      })
      .catch((error) => {
        toast.error("There was an error!", {
          closeButton: false,
        });
        this.toggleModifyVenue();
        this.setState({ loading: false });
        this.setState({ reloadVenueList: false });
        this.props.updateName(sessionStorage.getItem("venueName"), sessionStorage.getItem("venueID"));
      });
  };

  modifyVenueInfoWizard = (
    address1,
    address2,
    phoneNumber,
    country,
    city,
    region,
    zipcode,
    email,
    countdown,
    durationResident,
    durationGuest,
    contactFirstName,
    contactLastName,
    minVlanRes,
    maxVlanRes,
    minVlanGuest,
    maxVlanGuest,
    minVlanRoaming,
    maxVlanRoaming,
    venueType,
    enableShoppingCart,
    sendSubscriptionAddedEmail,
    sendSubscriptionEndEmail,
    allowOverlapping,
    displayFCCLabel,
    typicalLatency
  ) => {
    if (address2 === undefined) {
      address2 = "";
    }
    this.setState({ loading: true });
    this.setState({ reloadVenueList: true });
    const updateVenueAddress = {
      VenueID: sessionStorage.getItem("venueID"),
      AddressLine1: address1,
      AddressLine2: address2,
      City: city,
      State: region,
      PostalCode: zipcode,
      "Country.ISO3166A3": country,
      AccessToken: sessionStorage.getItem("AccessToken"),
    };
    const updateVenue = {
      VenueID: sessionStorage.getItem("venueID"),
      AccessToken: sessionStorage.getItem("AccessToken"),
      WelcomeEmail_countdown_hours: countdown,
      DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess:
        durationResident,
      DefaultSubscriptionDurationHours_LocalWirelessAccess: durationGuest,
      PrimaryContactGivenName: contactFirstName,
      PrimaryContactFamilyName: contactLastName,
      PrimaryContactEmailAddress: email,
      "PrimaryContactPhoneNumber.E164": phoneNumber,
      MinimumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
        minVlanRes,
      MaximumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess:
        maxVlanRes,
      MinimumAutoGeneratedVLAN_LocalWirelessAccess: minVlanGuest,
      MaximumAutoGeneratedVLAN_LocalWirelessAccess: maxVlanGuest,
      MinimumAutoGeneratedVLAN_GlobalWirelessRoaming: minVlanRoaming,
      MaximumAutoGeneratedVLAN_GlobalWirelessRoaming: maxVlanRoaming,
      VenueType: venueType,
      enableShoppingCart: enableShoppingCart,
      sendSubscriptionAddedEmail: sendSubscriptionAddedEmail,
      sendSubscriptionEndEmail: sendSubscriptionEndEmail,
      allowOverlappingSubscriptionWiredUnit: allowOverlapping,
      FCCLabel: {
       displayLabel: displayFCCLabel,
       TypicalLatency: typicalLatency
      }
    };
    Promise.all([
      axios.post(
        "/networkoperatorconsole/venues/updateVenueAddress",
        updateVenueAddress,
        { headers: {} }
      ),
      axios.post(
        "/networkoperatorconsole/venues/configureVenue",
        updateVenue,
        {
          headers: {},
        }
      ),
    ])
      .then(async ([res1, res2]) => {
        toast.success("Updated Venue!", {
          closeButton: false,
        });
        this.setState({ loading: false });
        this.updateNameAddVenue(sessionStorage.getItem("venueName"), sessionStorage.getItem("venueID"));
        this.setState({ loading: false });
        // this.toggleAddNewVenue();
        this.setState({
          reloadVenueList: false,
          venueID: sessionStorage.getItem("venueID"),
          tabNumber: 2,
          tabChanged: true
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          closeButton: false,
        });
        this.setState({
          loading: false,
          reloadVenueList: false,
          tabNumber: 1,
          tabChanged: false
        });
      });
  };

  addUnit = (venueID, unitName, unitBuilding, unitFloor, address1, address2, country, city, region, zipcode, purpose, vlan, selectedPlan, smsCode) => {
    if (address2 === undefined) {
      address2 = "";
    }
    this.setState({ loadingUnit: true });
    this.setState({ reloadVenueList: true });
    const data = {
      VenueID: venueID,
      UnitID: unitName,
      UnitFloor: unitFloor,
      UnitBuilding: unitBuilding,
      AddressLine1: address1,
      AddressLine2: address2,
      City: city,
      State: region,
      PostalCode: zipcode,
      "Country.ISO3166A3": country,
      AccessToken: sessionStorage.getItem("AccessToken"),
      vlan: vlan,
      purpose: purpose,
      ServicePlanID: selectedPlan,
      SMSCode: smsCode
    };
    axios
      .post("/networkoperatorconsole/venues/addVenueUnits", data, {
        headers: {},
      })

      .then(
        (response) => {
          this.props.updateName(sessionStorage.getItem("venueName"), venueID);
          toast.success("Added Unit to Venue!", {
            closeButton: false,
          });
          this.setState({ loadingUnit: false });
          this.toggleAddUnit();
          this.setState({ reloadVenueList: false });
        },
        (error) => {
          toast.error("There was an error", {
            closeButton: false,
          });
          this.setState({ loadingUnit: false });
          this.setState({ reloadVenueList: false });
        }
      );
  };

  toggleError = () => {
    this.setState({
      modalError: !this.state.modalError,
    });
  };

  submitAddDomainAdministrators = (
    email,
    firstName,
    lastName,
    language,
    role
  ) => {
    this.setState({ add_domainadmin: true });

    let body = {
      AccessToken: sessionStorage.getItem("AccessToken"),
      EmailAddress: email,
      GivenName: firstName,
      FamilyName: lastName,
      locale: language,
      Role: role,
    };
    axios
      .post("/networkoperatorconsole/staff/enableStaffAccess", body, {
        headers: {},
      })
      .then(
        (response) => {
          toast.success("Added Staff!", {
            closeButton: false,
          });
          this.toggleAddDomainAdministrator();
          this.props.updateName(this.props.venueName, this.props.venueID);
          this.setState({ add_domainadmin: false });
        },
        (error) => {
          toast.error("There was an error!", {
            closeButton: false,
          });
          this.toggleAddDomainAdministrator();
          this.setState({ add_domainadmin: false });
        }
      );
  };

  getVenues = (selectedID) => {
    console.log(selectedID)
    var formArrayVenues = [];
    let optionTemplateVenues = [];
    this.setState({
      attributeForm: [],
      venueList: undefined,
      selectedID: selectedID
    });
    let body = { AccessToken: sessionStorage.getItem("AccessToken") };
    axios
      .post("/networkoperatorconsole/venues/listVenues", body, {
        headers: {},
      })
      .then((response) => {

        this.setState({ venueList: response.data.VenueList });
        Object.keys(response.data.VenueList).map((oneKey, i) => {
          if (selectedID === undefined) {
            this.setState({ firstID: oneKey });
          }
          if (selectedID === oneKey) {
            optionTemplateVenues.push(
              <MDBSelectOption selected value={oneKey}>
                {response.data.VenueList[oneKey]["VenueName"]}
              </MDBSelectOption>
            );
          } else {
            optionTemplateVenues.push(
              <MDBSelectOption value={oneKey}>
                {response.data.VenueList[oneKey]["VenueName"]}
              </MDBSelectOption>
            );
          }
          // this.setState({ optionTemplateVenues: optionTemplateVenues })
          return true;
        });
        formArrayVenues.push(
          <MDBSelect
            id="selectVenueSubscriberModal"
            getValue={this.handleSelectChangeVenue}
            className="form-control"
          >
            <MDBSelectInput
              className="selectInput venueSelectedResidentModal"
              selected="Choose your venue"
            />
            <MDBSelectOptions search>{optionTemplateVenues}</MDBSelectOptions>
          </MDBSelect>
        );
        this.setState({
          attributeForm: formArrayVenues,
        });
        if (selectedID !== undefined) {
          this.getVenueDetails(selectedID);
        }
      })
      .catch((error) => {
        this.setState({ error: true });
      });

  };

  handleSelectChangeVenue = (value) => {
    var venue = document.getElementsByClassName("venueSelectedResidentModal")[0].value;
    var lDiv = document.createElement("div");

    document.body.appendChild(lDiv);

    lDiv.style.fontSize = "" + 20 + "px";
    lDiv.style.position = "absolute";
    lDiv.style.left = -1000;
    lDiv.style.top = -1000;

    lDiv.innerHTML = venue;

    document.body.removeChild(lDiv);
    lDiv = null;
    sessionStorage.setItem("venueName", venue);
    sessionStorage.setItem("venueID", value[0]);
    this.setState({ selectedID: value[0] });
    this.updateName(venue, value[0]);
  };

  handleSelectChangeVenueFromList = (venueName, venueID) => {
    sessionStorage.setItem("venueName", venueName);
    sessionStorage.setItem("venueID", venueID);
    this.getVenues(venueID);
    this.setState({ selectedID: venueID });
    this.updateName(venueName, venueID);
  };

  updateName = (value, index) => {
    sessionStorage.setItem("venueName", value);
    sessionStorage.setItem("venueID", index);
    this.setState({
      venueID: index,
      venueName: value,
    });
    this.getVenueDetails(index);
  };

  updateNameAddVenue = (value, index) => {
    sessionStorage.setItem("venueName", value);
    sessionStorage.setItem("venueID", index);
    this.setState({
      venueID: index,
      venueName: value,
    });
  };

  render() {
    if (this.state.postMessage) {
      var h = document.getElementById("overlayResidentManagement");
      h.style.display = "block";
      setTimeout(function () {
        h.style.display = "block";
      }, 5000);
      setTimeout(() => {
        h.style.display = "none";
        this.setState({ postMessage: "" });
      }, 6000);
    }
    return (
      <>
        <MDBModalHeader toggle={this.props.toggleGuestManagement}>
          <h4>Venue Management</h4>
        </MDBModalHeader>
        <MDBModalBody>
          <div className="managementModal">
            <MDBRow>
              {
                sessionStorage.getItem("customerSupport") > 0 ? "" :
                  <MDBCol md="4">
                    <div className="managementModalButton" onClick={this.toggleAddNewVenue}>
                      <MDBIcon icon="user-plus" />
                      <p>
                        Add New
                        <br /> Venue
                      </p>
                    </div>
                  </MDBCol>
              }

              <MDBCol md="4">
                <div className="managementModalButton" onClick={this.toggleModifyVenue}>
                  <MDBIcon icon="edit" />
                  <p>View/Modify Venue</p>
                </div>
              </MDBCol>
              <MDBCol md="4">
                <div className="managementModalButton" onClick={this.toggleViewModifyVenues}>
                  <MDBIcon icon="edit" />
                  <p>
                    View All
                    <br /> Venues
                  </p>
                </div>
              </MDBCol>
              {
                sessionStorage.getItem("customerSupport") > 0 ? "" :
                  <>
                    <MDBCol md="4">
                      <div className="managementModalButton" onClick={this.toggleAddUnit}>
                        <MDBIcon icon="plus-circle" />
                        <p>Add Unit</p>
                      </div>
                    </MDBCol>
                    <MDBCol md="4">
                      <div className="managementModalButton" onClick={this.toggleDownloadUnits}>
                        <MDBIcon icon="cloud-download-alt" />
                        <p>Export Units</p>
                      </div>
                    </MDBCol>
                    <MDBCol md="4">
                      <div className="managementModalButton" onClick={this.toggleUploadUnits}>
                        <MDBIcon icon="cloud-upload-alt" />
                        <p>Upload Units</p>
                      </div>
                    </MDBCol>
                  </>
              }

            </MDBRow>
          </div>
        </MDBModalBody>
        <MDBModal isOpen={this.state.modalAddNewVenue} toggle={this.toggle} size="fluid" className="form" centered>
          <AddNewVenue
            toggleAddNewVenue={this.toggleAddNewVenue}
            submitHandler={this.addVenue}
            loading={this.state.loading}
            getVenues={this.getVenues}
            venueID={this.state.venueID}
            venueName={sessionStorage.getItem("venueName")}
            modifyVenue={this.modifyVenue}
            modifyVenueInfo={this.modifyVenueInfoWizard}
            submitDelete={this.submitDelete}
            sharedSecret={this.state.sharedSecret}
            attributeForm={this.state.attributeForm}
            venueInfo={this.state.venueInfo}
            loadingVenueDetails={
              this.state.loadingVenueDetails
            }
            groupForm={this.state.groupForm}
            keypoolForm={this.state.keypoolForm}
            unitsArrayAll={this.state.unitsArrayAll}
            keyPoolList={this.state.keyPoolList}
            servicePlans={this.state.servicePlans}
            staffArrayAll={this.state.staffArrayAll}
            venueEquipList={this.state.venueEquipList}
            getVenueDetails={this.getVenueDetails}
            updateName={this.updateName}
            keypoolID={this.state.keypoolID}
            propMgmtID={this.state.propMgmtID}
            tabNumber={this.state.tabNumber}
            tabChanged={this.state.tabChanged}
            selectDefaultServicePlanResident={this.state.selectDefaultServicePlanResident}
            selectDefaultServicePlanWireless={this.state.selectDefaultServicePlanWireless}
            selectedID={this.state.selectedID}
            toggleConfirmContinue={this.toggleConfirmContinue}
            toggleConfirmContinueOpen={this.state.toggleConfirmContinueOpen}
            modal={this.state.modal}
            allowOverlapping={this.props.allowOverlapping}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalModifyVenue} toggle={this.toggle} size="fluid" className="form" centered>
          <ModifyVenue
            toggleModifyVenue={this.toggleModifyVenue}
            modifyVenueInfo={this.modifyVenueInfo}
            submitDelete={this.submitDelete}
            sharedSecret={this.state.sharedSecret}
            attributeForm={this.state.attributeForm}
            venueInfo={this.state.venueInfo}
            loadingVenueDetails={this.state.loadingVenueDetails}
            getVenues={this.getVenues}
            groupForm={this.state.groupForm}
            keypoolForm={this.state.keypoolForm}
            unitsArrayAll={this.state.unitsArrayAll}
            keyPoolList={this.state.keyPoolList}
            servicePlans={this.state.servicePlans}
            staffArrayAll={this.state.staffArrayAll}
            venueEquipList={this.state.venueEquipList}
            getVenueDetails={this.getVenueDetails}
            updateName={this.updateName}
            keypoolID={this.props.keypoolID}
            propMgmtID={this.state.propMgmtID}
            loading={this.state.loading}
            venueID={this.state.venueID}
            clearDropDown={true}
            currencyName={this.state.currencyName}
            optionTemplateVenues={this.state.optionTemplateVenues}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalViewModifyVenues} toggle={this.toggle} size="fluid" className="form" centered>
          <ViewModifyVenues
            toggleViewModifyVenues={this.toggleViewModifyVenues}
            submitModify={this.submitModify}
            venueID={this.state.venueID}
            loading={this.state.loading}
            submitDelete={this.submitDelete}
            venueList={this.state.venueList}
            venueListForTable={this.props.venueListForTable}
            sharedSecret={this.state.sharedSecret}
            attributeForm={this.state.attributeForm}
            venueInfo={this.state.venueInfo}
            loadingVenueDetails={this.state.loadingVenueDetails}
            getVenues={this.getVenues}
            groupForm={this.state.groupForm}
            keypoolForm={this.state.keypoolForm}
            unitsArrayAll={this.state.unitsArrayAll}
            keyPoolList={this.state.keyPoolList}
            servicePlans={this.state.servicePlans}
            staffArrayAll={this.state.staffArrayAll}
            venueEquipList={this.state.venueEquipList}
            getVenueDetails={this.getVenueDetails}
            updateName={this.updateName}
            handleSelectChangeVenueFromList={this.handleSelectChangeVenueFromList}
            keypoolID={this.props.keypoolID}
            propMgmtID={this.state.propMgmtID}
            selectedID={this.state.selectedID}
            optionTemplateVenues={this.state.optionTemplateVenues}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalAddUnit} toggle={this.toggle} size="lg" className="form" centered>
          <AddUnit
            toggleAddUnit={this.toggleAddUnit}
            venueID={this.state.venueID}
            venueName={sessionStorage.getItem("venueName")}
            sharedSecret={this.state.sharedSecret}
            submitAddUnit={this.addUnit}
            loadingUnit={this.state.loadingUnit}
            attributeForm={this.state.attributeForm}
            hasUpdated={this.state.hasUpdated}
            selectedID={this.state.selectedID}
            address1={this.state.address1}
            address2={this.state.address2}
            city={this.state.city}
            region={this.state.region}
            country={this.state.country}
            postalcode={this.state.postalcode}
            optionTemplatePlans={this.state.optionTemplatePlans}
            optionTemplateVenues={this.state.optionTemplateVenues}
            optionTemplateServicePlans={this.state.optionTemplateServicePlans}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalServicesEndingSoon} toggle={this.toggle} size="fluid" className="form" centered>
          <ServicesEndingSoonGuests
            toggleServicesEndingSoon={this.toggleServicesEndingSoon}
            expirations={this.props.guestDataExpiring}
            venueID={this.state.venueID}
            venueName={this.props.venueNameSelected}
            updateName={this.props.updateName}
            venueType={this.props.venueType}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalDownloadUnits} toggle={this.toggle} size="md" className="form" centered>
          <DownloadUnits
            toggleDownloadUnits={this.toggleDownloadUnits}
            venueID={this.state.venueID}
            unitsArrayAll={this.state.unitsArrayAll}
            venueName={this.props.venueNameSelected}
            updateName={this.props.updateName}
            venueType={this.props.venueType}
            UnitsBuildingFloorListSusbcribers={this.props.UnitsBuildingFloorListSusbcribers}
            attributeForm={this.state.attributeForm}
            loadingVenueDetails={this.state.loadingVenueDetails}
            hasUpdated={this.state.hasUpdated}
          />
        </MDBModal>
        <MDBModal isOpen={this.state.modalUploadUnits} toggle={this.toggle} size="md" className="form" centered>
          <UploadUnits
            toggleUploadUnits={this.toggleUploadUnits}
            venueID={this.state.venueID}
            unitsArrayAll={this.state.unitsArrayAll}
            venueName={this.props.venueNameSelected}
            updateName={this.props.updateName}
            sharedSecret={this.state.sharedSecret}
            attributeForm={this.state.attributeForm}
            selectedID={this.state.selectedID}
          />
        </MDBModal>
        <MDBModal
          isOpen={this.state.modalAddDomainAdministrator}
          toggle={this.toggle}
          size="lg"
          className="form"
          centered
        >
          <AddDomainAdministrator
            submitAddDomainAdministrators={this.submitAddDomainAdministrators}
            toggleAddDomainAdministrator={this.toggleAddDomainAdministrator}
            add_domainadmin={this.state.add_domainadmin}
            loading={this.state.loading}
            updateName={this.props.updateName}
          />
        </MDBModal>
      </>
    );
  }
}

export default withTranslation()(VenueManagementNavigation);