import React from "react";

import { MDBDataTableV5 } from "mdbreact";

export default function Basic(props) {
  let userAttributes;
  let i = 0;

  let datatable;
  [datatable] = React.useState("");
  if (Object.keys(props.unitSubscriptionsList).length !== 0) {
    userAttributes = [];
    for (const key of Object.keys(props.unitSubscriptionsList)) {
      let Address2 = props.unitSubscriptionsList[key]['AddressLine2']!==""?props.unitSubscriptionsList[key]['AddressLine2']+"\n\r":""
      userAttributes.push({
        subName: (
              props.unitSubscriptionsList[key]["SubscriberGivenName"] + " " + props.unitSubscriptionsList[key]["SubscriberFamilyName"]
        ),
        subAddress: props.unitSubscriptionsList[key]['AddressLine1']+"\n\r"+Address2+props.unitSubscriptionsList[key]['City']+", "+props.unitSubscriptionsList[key]['State']+" "+props.unitSubscriptionsList[key]['PostalCode'],
        subPhone: props.unitSubscriptionsList[key]["SubscriberPhoneNumber.E164"],
        subEmail: props.unitSubscriptionsList[key]["SubscriberEmail"],
        subInService: props.unitSubscriptionsList[key]["Status"],
        subStartDate:
          props.unitSubscriptionsList[key]["StartDateTimeUTC.ISO8601"] !== ""
            ? Intl.DateTimeFormat("en-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "numeric",
                minute: "numeric",
              }).format(new Date(props.unitSubscriptionsList[key]["StartDateTimeUTC.ISO8601"]))
            : "",
        clickEvent: () => props.modifyResident(props.unitSubscriptionsList[key]),
      });
      i = i + 1;
    }
  }

  datatable = ({
    columns: [
      {
        label: "Subscriber Name",
        field: "subName",
      },
      {
        label: "Address",
        field: "subAddress",
      },
      {
        label: "Phone",
        field: "subPhone",
      },
      {
        label: "Email",
        field: "subEmail",
      },
      {
        label: "Service Status",
        field: "subInService",
      },
      {
        label: "Start Date",
        field: "subStartDate",
      },
    ],
    rows: userAttributes,
  });
  return (
    <MDBDataTableV5
      sortable={true}
      responsive
      hover
      data={datatable}
      searchTop={true}
      searchBottom={false}
      searchLabel="Search for Subscriber"
      paging={false}
      className="recentResidents"
      info={false}
      noRecordsFoundLabel="No Subscribers Found"
      sortRows={["subStartDate"]}
    />
  );
}